<template>
  <div class="home">
    <HelloWorld :msg="message"/>
    <div>
      <h3>The current API url is:</h3>
      <p v-text="apiUrl"></p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import axios from 'axios';

export default defineComponent({
  name: 'HomeView',
  components: {
    HelloWorld,
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL || 'http://localhost',
      message: 'Welcome to Budgetizr',
    };
  },
  mounted() {
    console.log('HomeView mounted');
    axios.get(this.apiUrl + "/").then((response) => {
      if (response) {
        this.message = response.data.message;
      }
      console.log(response);
    });
  },
});
</script>
